<template>
  <div class="add-block">
    <SubHeaderBlock />
    <b-spinner v-if="spinnerShow" class="spinner" label="Large Spinner" />
    <div class="container">
      <component :is="block" class="add-block__component" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      spinnerShow: false,
    };
  },
  components: {
    SubHeaderBlock: () => ({
      component: import("./components/subHeader"),
    }),
    NewCounterpartyBlock: () => ({
      component: import("./components/newCounterparty"),
    }),
    NewTransportBlock: () => ({
      component: import("./components/newTransport"),
    }),
    NewCarriageBlock: () => ({
      component: import("./components/newCarriage"),
    }),
    NewDriverBlock: () => ({
      component: import("./components/newDriver"),
    }),
    NewExpeditorBlock: () => ({
      component: import("./components/newExpeditor"),
    }),
    NewManagerBlock: () => ({
      component: import("./components/newManager"),
    }),
    NewCityBlock: () => ({
      component: import("./components/newCity"),
    }),
    NewRegionBlock: () => ({
      component: import("./components/newRegion"),
    }),
    NewPurchaseBlock: () => ({
      component: import("./components/newPurchase"),
    }),
    NewCategoriesBlock: () => ({
      component: import("./components/newCategories"),
    }),
    NewOrderBlock: () => ({
      component: import("./components/newOrder"),
    }),
    NewCostBlock: () => ({
      component: import("./components/newCost"),
    }),
  },
  computed: {
    block() {
      return this.$route.params.type + "-block";
    },
  },
  methods: {
    spinnerIn()
    {
      this.spinnerShow = true;
    },
    spinnerOut()
    {
      this.spinnerShow = false;
    },
  }
};
</script>

<style scoped>
.add-block__component {
  margin: 20px 0;
}
</style>
